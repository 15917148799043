export const REQUIRED_FIELD = "Поле обязательно к заполнению";
export const INVALID_EMAIL = "Введите корректный адрес почты";
export const INVALID_PHONE = "Введите корректный номер телефона";
export const INVALID_LINK = "Введите корректную ссылку";
export const INVALID_INN = "ИНН может состоять только из 10 или 12 цифр";
export const INVALID_STRING = "Использование недопустимых символов";
export const INVALID_NUMBER = "Поле может содержать только цифры";
export const INVALID_PASSWORD = "Пароль должен содержать ";
export const API_HOST = process.env.VUE_APP_API_HOST;

export const KEYS = {
	USER: 'user',
	TOKEN: 'token'
};

export const publicSections = [
	'page-main',
	'users/verify'
]

export const USER_ROLE = {
	MODERATOR: "Модератор",
	EMPLOYEE: "Сотрудник",
	ADMIN: "Администратор с ограничением прав доступа",
	VALID_ADMIN: "Администратор без ограничения прав доступа"
};

export const statusQuery = {
	SUCCESS: "success",
	ERROR: "error",
	PENDING: "pending"
};

export const authFields = {
	login: "identifier",
	password: "password"
}

export const statusGroup = {
	ACTIVE: "Активна",
	SUSPENDED: "Приостановлена",
	NOTSTARTED: "Не запущена",
	COMPLETED: "Завершена"
};

export const actionGroup = {
	CREATE_NEW: "Запустить новую",
	RESUME_COMPLETED: "Возобновить завершенную",
	RESUME_SUSPENDED: "Возобновить приостановленную",
	START: "Запустить",
	JOIN: "Присоединиться"
};

export const fieldsEvent = {
	date: "date",
	type: "type",
	description: "description"
};

export const fieldsNewGroup = {
	direction: "direction",
	comment: "comment",
	action: "action"
};

export const fieldsNewCluster = {
	name: "name",
	comment: "comment"
};

export const fieldsGroupForms = {
	comment: "comment",
	action: "action",
	workingGroup: "workingGroup"
};

export const filterList = {
	CITY: "cities",
	WORKERS: "workers",
	FORMAT: "formats",
	GROUP_STATUS: "status",
	EVENT_TYPE: "type",
	DIRECTION: "directions",
	SERVICE: "services",
	SPECIALIZATION: "specializations",
	CHANNEL: "channels"
};

export const filtersEvents = {
	[filterList.EVENT_TYPE]: "type",
	[filterList.DIRECTION]: "directions"
};

export const filtersGroups = {
	[filterList.GROUP_STATUS]: "status",
	[filterList.DIRECTION]: "direction"
};

export const fieldsUpdateCompany = {
	name: "name",
	inn: "inn",
	yearFoundation: "yearFoundation",
	site: "site",
	cities: "cities",
	remote: "remote",
	newCity: "newCity",
	format: "format",
	workers: "workers",
	services: "services",
	specializations: "specializations",
	channels: "channels"
};
