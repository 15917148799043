<template>
	<div class="p-4">
		<div class="max-w-lg w-full mx-auto mb-4">
			<progress-bar
				v-if="currentStep <= steps.length - 1"
				:steps="steps"
				:current-step="currentStep"
			/>
			<span
				v-if="currentStep > 0 && currentStep !== steps.length"
				class="cursor-pointer text-gray-400 -mt-2"
				@click="prevStep"
			>
				К предыдущему шагу
			</span>
		</div>

		<step1
			v-show="currentStep === 0"
			@toNext="nextStep"
		/>

		<step2
			v-show="currentStep === 1"
			@toNext="nextStep"
		/>
		
		<step3
			v-show="currentStep === 2"
			@finish="onSubmit"
		/>

		<finish-step
			:error="error"
			:success="success"
		/>
	</div>
</template>

<script>
import ProgressBar from "@/components/ProgressBar/ProgressBar";
import Step1 from "@/pages/RegistrationPage/FormRegCompanySteps/Step1";
import Step2 from "@/pages/RegistrationPage/FormRegCompanySteps/Step2";
import Step3 from "@/pages/RegistrationPage/FormRegCompanySteps/Step3";
import FinishStep from "@/pages/RegistrationPage/FormRegCompanySteps/FinishStep";

import { registrationCompany } from "@/api";

export default {
	name: "FormRegCompany",
	components: { Step3, Step2, ProgressBar, Step1, FinishStep },
	data() {
		return {
			currentStep: 0,
			steps: [
				{ id: 'step1', title: 'Шаг 1' },
				{ id: 'step2', title: 'Шаг 2' },
				{ id: 'step3', title: 'Шаг 3' }
			],
			data: [],
			error: false,
			success: false,
			messageBody: {
				user: null,
				company: null
			}
		}
	},
	methods: {
		nextStep(data) {
			this.data = { ...this.data, ...data };
			
			if (this.currentStep > this.steps.length) return;
			
			this.currentStep++;
		},
		prevStep() {
			if (this.currentStep === 0) return;
			
			this.currentStep--;
		},
		onSubmit (data) {
			this.nextStep(data);

			const registerData = {
				data: {
					company: {
						data: {
							name: this.data.companyName.value,
							inn: this.data.inn.value,
							yearFoundation: this.data.yearFoundation.value,
							site: this.data.site.value,
							cities: this.data.cities.value,
							newCity: this.data.newCity.value,
							format: this.data.format.value,
							workers: this.data.workers.value,
							services: this.data.services.value,
							specializations: this.data.specializations.value,
							channels: this.data.channels.value,
							remote: this.data.remote.value,
							publishedAt: null
						}
					},
					user: {
						data: {
							name: this.data.name.value,
							email: this.data.email.value,
							phone: this.data.phone.value,
							username:this.data.email.value,
							lastName: this.data.lastName.value,
							password: this.data.password.value,
							post: this.data.post.value,
							userRole: "Администратор с ограничением прав доступа"
						}
					}
				}
			};

			registrationCompany(registerData)
				.then(result => {
					this.success = true;
					return result
				})
				.catch(e => {
					this.error = true;
					console.log(e)
				})
		}
	}
}
</script>

<style scoped>

</style>
